.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #A3A9B1;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 3px;
  top: 2px;
  width: 18px;
  height: 18px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transition: transform 0.3s ease;
      border: 1px solid #FFF;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(14px);
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #256BF6;
}
